export const words5: string[] = [
  'aaloe',
  'aalto',
  'aamen',
  'aaria',
  'aarre',
  'aatos',
  'aatra',
  'aatto',
  'ahava',
  'ahdas',
  'ahdin',
  'ahkio',
  'ahmia',
  'ahnas',
  'ahtaa',
  'ahven',
  'aidas',
  'aihio',
  'aijai',
  'aikoa',
  'aines',
  'ainoa',
  'ainut',
  'aioli',
  'airut',
  'aisti',
  'aitio',
  'aitoa',
  'aitta',
  'aivan',
  'aivot',
  'ajaja',
  'ajelu',
  'ajuri',
  'akana',
  'aktio',
  'alati',
  'alava',
  'aleta',
  'alias',
  'alibi',
  'alkaa',
  'alkio',
  'allas',
  'aloke',
  'alppi',
  'altis',
  'altto',
  'aluke',
  'aluna',
  'ambra',
  'ameba',
  'ammis',
  'ammua',
  'ammus',
  'ammuu',
  'ampua',
  'ankea',
  'ankka',
  'annos',
  'anodi',
  'anoja',
  'ansas',
  'ansio',
  'antaa',
  'apaja',
  'apeus',
  'apila',
  'apina',
  'apnea',
  'appaa',
  'apuri',
  'arabi',
  'arava',
  'argon',
  'arina',
  'arkki',
  'arkki',
  'arkku',
  'armas',
  'aromi',
  'arpoa',
  'arvio',
  'asema',
  'asemo',
  'asete',
  'askar',
  'askel',
  'astia',
  'astin',
  'astma',
  'astua',
  'asuja',
  'asuma',
  'atari',
  'atlas',
  'atomi',
  'aueta',
  'aukea',
  'aukio',
  'aukko',
  'aukoa',
  'aulio',
  'aulis',
  'aunus',
  'aussi',
  'autio',
  'avain',
  'avara',
  'avata',
  'avaus',
  'avoin',
  'azeri',
  'baana',
  'baari',
  'baari',
  'balsa',
  'bambu',
  'banjo',
  'bantu',
  'barbi',
  'bardi',
  'baski',
  'basso',
  'baudi',
  'bebee',
  'bebop',
  'beesi',
  'beeta',
  'beibi',
  'beige',
  'bensa',
  'bidee',
  'biisi',
  'bilsa',
  'bingo',
  'bitti',
  'blini',
  'blues',
  'boksi',
  'boksi',
  'bongo',
  'bonus',
  'booli',
  'boomi',
  'boori',
  'bravo',
  'bromi',
  'bukee',
  'bulla',
  'bussi',
  'buumi',
  'buuri',
  'bygga',
  'bändi',
  'bänet',
  'bänks',
  'chili',
  'chips',
  'civis',
  'coupé',
  'crack',
  'crêpe',
  'curry',
  'daami',
  'dandy',
  'datša',
  'debet',
  'deeku',
  'dekki',
  'delta',
  'depis',
  'diiva',
  'diodi',
  'dippi',
  'disko',
  'dogmi',
  'donna',
  'dorka',
  'doula',
  'durra',
  'duuma',
  'duuni',
  'duuri',
  'dyyni',
  'edetä',
  'eeden',
  'eepos',
  'eesti',
  'eheys',
  'ehken',
  'ehtiä',
  'ehtoo',
  'ehtyä',
  'eilen',
  'eines',
  'eksyä',
  'ellei',
  'eloon',
  'elpyä',
  'eläin',
  'eläjä',
  'eläke',
  'elämä',
  'elävä',
  'emali',
  'empiä',
  'enetä',
  'ennen',
  'ensin',
  'entäs',
  'entää',
  'epeli',
  'epäys',
  'erite',
  'eritä',
  'eroon',
  'erota',
  'esiin',
  'esine',
  'esite',
  'essee',
  'estin',
  'estyä',
  'estää',
  'etana',
  'eteen',
  'etelä',
  'etevä',
  'etsin',
  'etsiä',
  'etuus',
  'etydi',
  'eukko',
  'evätä',
  'faasi',
  'faija',
  'fakki',
  'faksi',
  'fakta',
  'farao',
  'farmi',
  'fatsi',
  'fauna',
  'fauni',
  'fiini',
  'fikka',
  'fiksu',
  'filee',
  'filmi',
  'finis',
  'finni',
  'firma',
  'fobia',
  'fokka',
  'foksi',
  'fokus',
  'folio',
  'formu',
  'forte',
  'fudia',
  'fudis',
  'fudut',
  'fuksi',
  'fusku',
  'futia',
  'futis',
  'fuuga',
  'fylli',
  'fääri',
  'fööni',
  'gaala',
  'gabro',
  'gaeli',
  'gamma',
  'gasti',
  'geeli',
  'geeni',
  'geiša',
  'genre',
  'getto',
  'gimma',
  'glögi',
  'gongi',
  'gouda',
  'gradu',
  'grape',
  'grogi',
  'guano',
  'guava',
  'gängi',
  'haamu',
  'haapa',
  'haara',
  'haava',
  'haava',
  'haave',
  'haavi',
  'hahlo',
  'hahmo',
  'haiku',
  'haiku',
  'haili',
  'haima',
  'haisu',
  'haite',
  'hakea',
  'hakku',
  'halia',
  'halju',
  'halki',
  'halko',
  'halla',
  'halli',
  'halli',
  'halma',
  'halme',
  'haloo',
  'halpa',
  'halva',
  'handu',
  'hanhi',
  'hanka',
  'hanke',
  'hanki',
  'hanko',
  'hansa',
  'hanti',
  'hapan',
  'happi',
  'happo',
  'hapro',
  'hapsi',
  'hapsu',
  'harha',
  'harja',
  'harju',
  'harme',
  'harmi',
  'haroa',
  'harri',
  'harso',
  'harsu',
  'harus',
  'harva',
  'hasis',
  'hassi',
  'hassu',
  'hattu',
  'haude',
  'hauis',
  'hauki',
  'hauli',
  'haura',
  'hauta',
  'hauva',
  'havas',
  'heavy',
  'hefta',
  'hehku',
  'hehto',
  'heila',
  'heili',
  'heimo',
  'heinä',
  'heisi',
  'heite',
  'heleä',
  'helke',
  'hella',
  'helle',
  'hellä',
  'helma',
  'helmi',
  'helpi',
  'helve',
  'hemmo',
  'henki',
  'henna',
  'henry',
  'hento',
  'heppa',
  'heppu',
  'hereä',
  'herja',
  'hermo',
  'herne',
  'herra',
  'herua',
  'hetiö',
  'hetki',
  'hevin',
  'hidas',
  'hieho',
  'hieno',
  'hiesu',
  'hieta',
  'hihna',
  'hiili',
  'hiiop',
  'hiiri',
  'hiisi',
  'hiiva',
  'hikka',
  'hilla',
  'hillo',
  'hilse',
  'hilut',
  'hindi',
  'hindu',
  'hinku',
  'hinta',
  'hioke',
  'hiomo',
  'hiota',
  'hipat',
  'hipiä',
  'hipoa',
  'hippa',
  'hippi',
  'hippu',
  'hirmu',
  'hirsi',
  'hirvi',
  'hissa',
  'hissi',
  'hitsi',
  'hitsi',
  'hitti',
  'hitto',
  'hiuka',
  'hiven',
  'hobby',
  'hohde',
  'hohka',
  'hohoi',
  'hohto',
  'hoide',
  'hoito',
  'hoiva',
  'hokea',
  'holli',
  'holvi',
  'homma',
  'honka',
  'hoopo',
  'hopea',
  'hoppu',
  'hormi',
  'horna',
  'horre',
  'hosua',
  'houre',
  'house',
  'huhta',
  'huilu',
  'huima',
  'huivi',
  'hukka',
  'hullu',
  'humma',
  'humus',
  'hunni',
  'huntu',
  'huoku',
  'huoli',
  'huone',
  'huono',
  'huopa',
  'huora',
  'huovi',
  'huppu',
  'hupsu',
  'hurja',
  'hurma',
  'hurme',
  'hurri',
  'hutsu',
  'huttu',
  'huuli',
  'huuma',
  'huume',
  'huuri',
  'huuru',
  'huusi',
  'huuti',
  'huuto',
  'hyhmä',
  'hylje',
  'hylky',
  'hylly',
  'hylsy',
  'hymen',
  'hymiö',
  'hymni',
  'hyppy',
  'hyrrä',
  'hytti',
  'hyvin',
  'hyyde',
  'hyytö',
  'hyöky',
  'hyöty',
  'hyötö',
  'häijy',
  'häive',
  'häivä',
  'häkki',
  'häntä',
  'häpeä',
  'häppä',
  'härkä',
  'härme',
  'härmä',
  'häviö',
  'häätö',
  'häävi',
  'hääyö',
  'höhlä',
  'höllä',
  'hölmö',
  'höpsö',
  'höpön',
  'hörhö',
  'höskä',
  'höttö',
  'höyde',
  'höyli',
  'höylä',
  'höynä',
  'höyry',
  'höyty',
  'icing',
  'idoli',
  'ihana',
  'iibis',
  'iikka',
  'iiris',
  'ikinä',
  'ikoni',
  'ikänä',
  'ikävä',
  'ikäys',
  'ikään',
  'ilkeä',
  'ilkiö',
  'ilman',
  'ilmiö',
  'iltti',
  'ilves',
  'image',
  'imago',
  'imelä',
  'immyt',
  'imuke',
  'imuri',
  'ininä',
  'input',
  'inssi',
  'intos',
  'intro',
  'intti',
  'ipana',
  'irviä',
  'iskeä',
  'islam',
  'isota',
  'isota',
  'isous',
  'istua',
  'isyys',
  'itara',
  'itkeä',
  'ivata',
  'iäksi',
  'iäkäs',
  'iätön',
  'jaaha',
  'jaala',
  'jahka',
  'jahti',
  'jahti',
  'jakaa',
  'jakki',
  'jakki',
  'jakku',
  'jakso',
  'jalan',
  'jalas',
  'jalka',
  'jalus',
  'jambi',
  'jamit',
  'jannu',
  'jaoke',
  'japsi',
  'jarru',
  'jatke',
  'jatko',
  'jatsi',
  'jauhe',
  'jauho',
  'jekku',
  'jemma',
  'jengi',
  'jenka',
  'jeppe',
  'jermu',
  'jetti',
  'jiddi',
  'jiiri',
  'jippo',
  'johde',
  'johto',
  'joiku',
  'jokin',
  'jolla',
  'jolma',
  'jonne',
  'jooga',
  'joogi',
  'jooli',
  'jospa',
  'joten',
  'jotos',
  'jotta',
  'jouhi',
  'joule',
  'joulu',
  'jousi',
  'juhla',
  'juhta',
  'jukka',
  'jukra',
  'julki',
  'julli',
  'julma',
  'jumbo',
  'junnu',
  'juoda',
  'juoma',
  'juomu',
  'juoni',
  'juopa',
  'juoru',
  'juote',
  'juova',
  'juppi',
  'juroa',
  'jurri',
  'Jussi',
  'jutaa',
  'jutku',
  'juttu',
  'juuri',
  'juuri',
  'jylhä',
  'jyske',
  'jytke',
  'jytää',
  'jäkki',
  'jälki',
  'jälsi',
  'jänis',
  'jänkä',
  'jänne',
  'jännä',
  'järeä',
  'järin',
  'järki',
  'järvi',
  'jäsen',
  'jätkä',
  'jätti',
  'jättö',
  'jätös',
  'jäyhä',
  'jäynä',
  'jäädä',
  'jäähy',
  'jäämä',
  'jäärä',
  'jäävi',
  'kaade',
  'kaali',
  'kaaos',
  'kaapu',
  'kaara',
  'kaari',
  'kaari',
  'kaaso',
  'kaasu',
  'kaato',
  'kaava',
  'kahjo',
  'kahju',
  'kahle',
  'kahta',
  'kahva',
  'kahvi',
  'kaide',
  'kaihi',
  'kaiho',
  'kaiku',
  'kaima',
  'kaino',
  'kaira',
  'kaira',
  'kaita',
  'kaita',
  'kaivo',
  'kaivu',
  'kajal',
  'kakku',
  'kakru',
  'kaksi',
  'kalhu',
  'kalja',
  'kalju',
  'kalke',
  'kalla',
  'kalle',
  'kallo',
  'kalma',
  'kalmo',
  'kalpa',
  'kalsa',
  'kalvo',
  'kamee',
  'kammo',
  'kampa',
  'kampe',
  'kampi',
  'kandi',
  'kanki',
  'kanna',
  'kanna',
  'kanne',
  'kannu',
  'kansa',
  'kansi',
  'kanta',
  'kanto',
  'kanto',
  'kapea',
  'kappa',
  'kappa',
  'kappa',
  'kappi',
  'kapse',
  'kaput',
  'karhe',
  'karhi',
  'karho',
  'karhu',
  'karja',
  'karju',
  'karku',
  'karma',
  'karmi',
  'karri',
  'karsi',
  'karva',
  'karve',
  'kaski',
  'kasko',
  'kasku',
  'kassa',
  'kassi',
  'kaste',
  'kasti',
  'kasti',
  'kasvi',
  'kasvo',
  'kasvu',
  'katka',
  'katki',
  'katko',
  'katko',
  'katku',
  'katos',
  'katse',
  'katti',
  'katto',
  'katua',
  'katve',
  'kauan',
  'kauas',
  'kauha',
  'kauhu',
  'kaula',
  'kauna',
  'kaura',
  'kausi',
  'kavio',
  'kebab',
  'kehiä',
  'kehno',
  'kehnä',
  'kehrä',
  'kehto',
  'kehua',
  'kehys',
  'keija',
  'keiju',
  'keila',
  'keino',
  'keinu',
  'keiso',
  'kekri',
  'keksi',
  'keksi',
  'kelju',
  'kello',
  'kelmi',
  'kelmu',
  'kelpo',
  'kelta',
  'kemia',
  'kemut',
  'kendo',
  'kenkä',
  'kenno',
  'kepeä',
  'keppi',
  'kerho',
  'keriä',
  'kerma',
  'kerni',
  'kersa',
  'kerta',
  'kerto',
  'keruu',
  'kesiä',
  'kessu',
  'kessu',
  'kesti',
  'kesto',
  'ketju',
  'ketku',
  'ketsi',
  'ketto',
  'kettu',
  'keula',
  'keveä',
  'kevyt',
  'kevät',
  'khaki',
  'khmer',
  'kieli',
  'kielo',
  'kiero',
  'kierä',
  'kihti',
  'kiila',
  'kiilu',
  'kiima',
  'kiina',
  'kiire',
  'kiire',
  'kiiri',
  'kiiru',
  'kiisu',
  'kiito',
  'kiivi',
  'kikka',
  'kilju',
  'kilke',
  'kilpa',
  'kilpi',
  'kilsa',
  'kilta',
  'kimeä',
  'kimma',
  'kimpi',
  'kingi',
  'kinos',
  'kinua',
  'kipeä',
  'kippi',
  'kippo',
  'kipsa',
  'kipsi',
  'kireä',
  'kiriä',
  'kirja',
  'kirje',
  'kirjo',
  'kirnu',
  'kirsi',
  'kirsu',
  'kirva',
  'kiska',
  'kisko',
  'kissa',
  'kitka',
  'kitku',
  'kitsi',
  'kitsi',
  'kitti',
  'kitua',
  'kiuas',
  'kiulu',
  'kiuru',
  'kiusa',
  'kives',
  'klani',
  'klapi',
  'klubi',
  'koala',
  'kobra',
  'kohde',
  'kohta',
  'kohta',
  'kohti',
  'kohtu',
  'kohva',
  'koipi',
  'koira',
  'koisa',
  'koiso',
  'koite',
  'koivu',
  'kokea',
  'kokka',
  'kokki',
  'kokki',
  'kokko',
  'kokko',
  'koksi',
  'kolea',
  'kolho',
  'kolhu',
  'kolja',
  'kolke',
  'kolli',
  'kolli',
  'kollo',
  'kolme',
  'koloa',
  'kolvi',
  'komea',
  'kompa',
  'konki',
  'konna',
  'konsa',
  'kontu',
  'koodi',
  'kooma',
  'koota',
  'kopea',
  'kopio',
  'kopla',
  'koppa',
  'koppi',
  'koppi',
  'kopra',
  'kopse',
  'kopsu',
  'kopsu',
  'kopti',
  'korea',
  'korea',
  'koris',
  'korko',
  'korni',
  'korpi',
  'korsi',
  'korsu',
  'korte',
  'korva',
  'korvo',
  'košer',
  'kosia',
  'koska',
  'koski',
  'koste',
  'kosto',
  'kotia',
  'kotka',
  'kotoa',
  'kotsa',
  'kotva',
  'kouho',
  'koulu',
  'koura',
  'kouru',
  'kovaa',
  'kovin',
  'kovis',
  'krapu',
  'krimi',
  'kromi',
  'kränä',
  'kudin',
  'kudos',
  'kuhmu',
  'kuhun',
  'kuilu',
  'kuiri',
  'kuitu',
  'kuiva',
  'kukin',
  'kukka',
  'kukko',
  'kukku',
  'kuksa',
  'kulho',
  'kulju',
  'kulku',
  'kulma',
  'kulta',
  'kulti',
  'kulua',
  'kumea',
  'kumma',
  'kummi',
  'kumpi',
  'kumpu',
  'kundi',
  'kunne',
  'kunpa',
  'kunta',
  'kunto',
  'kuoha',
  'kuohu',
  'kuola',
  'kuolo',
  'kuoma',
  'kuomu',
  'kuona',
  'kuono',
  'kuore',
  'kuori',
  'kuori',
  'kuoro',
  'kuosi',
  'kuovi',
  'kupla',
  'kuppa',
  'kuppi',
  'kupro',
  'kupru',
  'kurdi',
  'kurho',
  'kurin',
  'kurja',
  'kurki',
  'kurko',
  'kuroa',
  'kurra',
  'kurre',
  'kurri',
  'kurva',
  'kurvi',
  'kuski',
  'kussa',
  'kusta',
  'kusta',
  'kutea',
  'kuten',
  'kutka',
  'kutoa',
  'kutsu',
  'kuttu',
  'kuula',
  'kuulo',
  'kuulu',
  'kuuma',
  'kuume',
  'kuura',
  'kuuri',
  'kuuri',
  'kuuro',
  'kuuro',
  'kuusi',
  'kuusi',
  'kuvas',
  'kuvio',
  'kyetä',
  'kyhmy',
  'kylki',
  'kyllä',
  'kylmä',
  'kylpy',
  'kylvö',
  'kymri',
  'kyniä',
  'kynsi',
  'kynte',
  'kyntö',
  'kypsi',
  'kypsä',
  'kyrpä',
  'kyrsä',
  'kyssä',
  'kysta',
  'kystä',
  'kysyä',
  'kyteä',
  'kytis',
  'kytky',
  'kyttä',
  'kyylä',
  'kyyry',
  'kyyti',
  'kyömy',
  'käheä',
  'känni',
  'känny',
  'känsä',
  'käreä',
  'kärhi',
  'kärhö',
  'kärki',
  'kärri',
  'kärry',
  'kärsä',
  'käsin',
  'käsky',
  'käsnä',
  'kätkö',
  'käydä',
  'käypä',
  'käyrä',
  'käyte',
  'käyvä',
  'käämi',
  'kääpä',
  'kääre',
  'käärö',
  'kääty',
  'köhiä',
  'kökkö',
  'kölli',
  'kölli',
  'kössi',
  'kössi',
  'köyhä',
  'köyry',
  'köysi',
  'köyte',
  'kööri',
  'laaja',
  'laaka',
  'laaki',
  'laama',
  'laama',
  'laari',
  'laata',
  'laatu',
  'laava',
  'laavu',
  'lados',
  'lafka',
  'lahja',
  'lahje',
  'lahko',
  'lahna',
  'lahti',
  'lahti',
  'laiha',
  'laiho',
  'laimi',
  'laina',
  'laine',
  'laita',
  'laite',
  'laiva',
  'lakea',
  'lakka',
  'lakka',
  'lakka',
  'lakki',
  'lakko',
  'lamee',
  'lampi',
  'lande',
  'lanka',
  'lanko',
  'lanne',
  'lanta',
  'laota',
  'lapio',
  'lappi',
  'lappo',
  'lappu',
  'lapsi',
  'largo',
  'laser',
  'lasko',
  'lasku',
  'lassi',
  'lasso',
  'lasta',
  'lasti',
  'lastu',
  'latoa',
  'latta',
  'latva',
  'laude',
  'lauha',
  'lauha',
  'laulu',
  'lauma',
  'lause',
  'lauta',
  'lavea',
  'leffa',
  'lehmä',
  'lehti',
  'lehto',
  'lehvä',
  'leidi',
  'leija',
  'leike',
  'leili',
  'leima',
  'leimu',
  'leini',
  'leipä',
  'leiri',
  'leivo',
  'lelli',
  'lempi',
  'lempo',
  'lenko',
  'lento',
  'lento',
  'leppä',
  'lepra',
  'lepsu',
  'lesbo',
  'leski',
  'lesti',
  'lesty',
  'lestä',
  'letka',
  'letku',
  'letti',
  'letto',
  'lettu',
  'leuka',
  'leuku',
  'leuto',
  'leveä',
  'liata',
  'lieju',
  'lieka',
  'lieko',
  'liemi',
  'lieri',
  'liero',
  'liesi',
  'liesu',
  'liesu',
  'liete',
  'lieve',
  'lievä',
  'lifti',
  'lihas',
  'lihoa',
  'liian',
  'liiga',
  'liika',
  'liike',
  'liila',
  'liima',
  'liina',
  'liira',
  'liite',
  'liito',
  'liitu',
  'liivi',
  'liivi',
  'likin',
  'likka',
  'liksa',
  'lilja',
  'limbo',
  'limsa',
  'linja',
  'linko',
  'linna',
  'linni',
  'lintu',
  'liota',
  'lipas',
  'lipeä',
  'lipoa',
  'lippa',
  'lippi',
  'lippo',
  'lippu',
  'lipua',
  'liriä',
  'lirua',
  'lisko',
  'lisko',
  'lista',
  'lisää',
  'litku',
  'litra',
  'litsa',
  'litsi',
  'litsi',
  'liuku',
  'liuos',
  'liuta',
  'loata',
  'lobby',
  'loden',
  'lohko',
  'lohtu',
  'loimi',
  'loimu',
  'loiva',
  'lojua',
  'lokki',
  'lokse',
  'lommo',
  'lonka',
  'loosi',
  'loota',
  'lopen',
  'loppu',
  'lordi',
  'loska',
  'lossi',
  'lotja',
  'lotta',
  'lotta',
  'lotto',
  'louhe',
  'luhta',
  'luhti',
  'luihu',
  'luiku',
  'luiru',
  'luisu',
  'lujaa',
  'lukea',
  'lukio',
  'lukki',
  'lukko',
  'luksi',
  'lulla',
  'lumen',
  'lumme',
  'lunki',
  'lunni',
  'luoda',
  'luode',
  'luode',
  'luoja',
  'luoko',
  'luola',
  'luoma',
  'luomi',
  'luomi',
  'luona',
  'luota',
  'luoti',
  'luoto',
  'luova',
  'luovi',
  'luppi',
  'luppo',
  'lusia',
  'luste',
  'lusto',
  'lutka',
  'luulo',
  'luumu',
  'luuri',
  'luuta',
  'lyhde',
  'LYHKI',
  'lyhty',
  'lyhyt',
  'lyijy',
  'lykky',
  'lymfa',
  'lypsy',
  'lyseo',
  'lysti',
  'lyydi',
  'lyyra',
  'lyödä',
  'lyöjä',
  'lähde',
  'lähes',
  'lähin',
  'lähin',
  'lähiö',
  'lähtö',
  'läike',
  'läkki',
  'läksy',
  'lälly',
  'lämpö',
  'länsi',
  'läppä',
  'läppä',
  'läppä',
  'läpse',
  'lärvi',
  'läsiä',
  'läski',
  'läsnä',
  'lässy',
  'lätkä',
  'lätsä',
  'lätti',
  'lätti',
  'lätty',
  'lääke',
  'lääni',
  'lääte',
  'läävä',
  'löllö',
  'lössi',
  'lössi',
  'lötkö',
  'löyhä',
  'löyly',
  'löysä',
  'löytö',
  'maagi',
  'maali',
  'maali',
  'maamo',
  'maaru',
  'maata',
  'maate',
  'macho',
  'mafia',
  'magia',
  'magma',
  'magna',
  'mahis',
  'mahla',
  'mahti',
  'maija',
  'maila',
  'maili',
  'maili',
  'maine',
  'maiti',
  'maito',
  'makea',
  'makki',
  'makro',
  'maksa',
  'maksi',
  'maksu',
  'makuu',
  'malja',
  'malka',
  'malli',
  'malmi',
  'malto',
  'malva',
  'mamba',
  'mambo',
  'mamma',
  'mango',
  'mania',
  'manna',
  'manne',
  'mansi',
  'manto',
  'mantu',
  'mappi',
  'marja',
  'marsu',
  'marto',
  'maski',
  'massa',
  'massi',
  'massu',
  'masto',
  'mataa',
  'matka',
  'matsi',
  'matta',
  'matti',
  'matti',
  'matto',
  'mauri',
  'media',
  'meikä',
  'mekko',
  'melko',
  'meloa',
  'melto',
  'mennä',
  'menyy',
  'merta',
  'mesoa',
  'messi',
  'messu',
  'mesta',
  'metka',
  'metku',
  'metri',
  'metro',
  'metso',
  'metsä',
  'mieli',
  'miero',
  'miete',
  'mieto',
  'mihin',
  'miilu',
  'miina',
  'mikin',
  'mikki',
  'mikro',
  'miksi',
  'milli',
  'mimmi',
  'miniä',
  'minne',
  'mirha',
  'mirri',
  'missi',
  'missä',
  'mistä',
  'miten',
  'mitra',
  'mitta',
  'modus',
  'moike',
  'moiré',
  'moite',
  'mokka',
  'mokka',
  'molli',
  'mones',
  'monta',
  'moodi',
  'mooli',
  'moppi',
  'mopsi',
  'moron',
  'mosel',
  'moska',
  'motti',
  'motti',
  'motto',
  'muhea',
  'muhia',
  'muhvi',
  'muija',
  'mukaa',
  'muksu',
  'mulli',
  'multa',
  'mummi',
  'mummo',
  'mummu',
  'munia',
  'muona',
  'muori',
  'muoti',
  'muoto',
  'muovi',
  'murea',
  'muren',
  'murha',
  'murhe',
  'murju',
  'murre',
  'mursu',
  'murto',
  'museo',
  'mussu',
  'musta',
  'muste',
  'mutka',
  'mutsi',
  'mutta',
  'muuan',
  'muuli',
  'muuri',
  'muusa',
  'muusi',
  'myhky',
  'mykiö',
  'mykkä',
  'mylly',
  'myrha',
  'myski',
  'mysli',
  'myssy',
  'mytty',
  'myydä',
  'myyjä',
  'myyrä',
  'myyty',
  'myödä',
  'myöhä',
  'myötä',
  'mähkä',
  'mäihä',
  'mäike',
  'mälli',
  'mälli',
  'mälsä',
  'mämmi',
  'mänty',
  'mäntä',
  'märkä',
  'mäsis',
  'mäski',
  'mätky',
  'mätäs',
  'mäyrä',
  'määre',
  'määrä',
  'mökki',
  'mölli',
  'mömmö',
  'mönjä',
  'möreä',
  'mörkö',
  'mössö',
  'mötti',
  'möyhy',
  'naali',
  'naama',
  'naara',
  'naava',
  'nafta',
  'nafti',
  'nahas',
  'nahka',
  'naida',
  'nakki',
  'nakki',
  'nakki',
  'nakki',
  'naksu',
  'nalle',
  'nalli',
  'nalli',
  'nanna',
  'nappa',
  'nappi',
  'nappo',
  'napsu',
  'narri',
  'nasse',
  'nassu',
  'nassu',
  'nasta',
  'natsa',
  'natsi',
  'nauha',
  'naula',
  'nauru',
  'nauta',
  'neiti',
  'neito',
  'nekku',
  'nekru',
  'neliö',
  'neljä',
  'neppi',
  'neste',
  'netto',
  'neula',
  'neule',
  'neuvo',
  'nidos',
  'nielu',
  'niemi',
  'nihti',
  'niini',
  'niisi',
  'niksi',
  'nimiö',
  'niobi',
  'nippa',
  'nippu',
  'nirri',
  'nirso',
  'niska',
  'nisse',
  'nisti',
  'nitoa',
  'nitro',
  'niuho',
  'nivel',
  'nivoa',
  'noeta',
  'noita',
  'nokka',
  'nolla',
  'nopea',
  'noppa',
  'nopsa',
  'norja',
  'norja',
  'normi',
  'norri',
  'norsu',
  'norua',
  'noste',
  'nosto',
  'notko',
  'nousu',
  'nouto',
  'nugaa',
  'nuhde',
  'nuija',
  'nuiva',
  'nukka',
  'nukke',
  'nukki',
  'nulju',
  'nummi',
  'nunna',
  'nuoli',
  'nuolu',
  'nuora',
  'nuori',
  'nuppi',
  'nuppu',
  'nurea',
  'nurin',
  'nurja',
  'nurmi',
  'nuttu',
  'nuuka',
  'nykiä',
  'nykyä',
  'nylky',
  'nylon',
  'nymfi',
  'nynny',
  'nyppy',
  'nyreä',
  'nysty',
  'nyöri',
  'nähdä',
  'näkki',
  'näkyä',
  'nälkä',
  'nänni',
  'näppi',
  'näppy',
  'näpsä',
  'närhi',
  'näsiä',
  'nätti',
  'näyte',
  'näätä',
  'nössö',
  'nöyrä',
  'ohari',
  'oheen',
  'oheta',
  'ohhoh',
  'ohimo',
  'ohjas',
  'ohjus',
  'ohuus',
  'oieta',
  'oijoi',
  'oikea',
  'oikku',
  'oikoa',
  'oinas',
  'oitis',
  'ojoon',
  'ojuke',
  'oksia',
  'olake',
  'oleva',
  'olija',
  'omata',
  'omena',
  'ommel',
  'omppu',
  'onkia',
  'ontto',
  'ontua',
  'oppia',
  'optio',
  'orava',
  'origo',
  'orkku',
  'osake',
  'osata',
  'ostaa',
  'ostos',
  'osuma',
  'osuus',
  'osuva',
  'ottaa',
  'ovela',
  'paali',
  'paalu',
  'paanu',
  'paasi',
  'paaši',
  'paavi',
  'paeta',
  'pahka',
  'pahki',
  'pahna',
  'pahus',
  'pahvi',
  'paine',
  'paini',
  'paino',
  'paise',
  'paita',
  'pakka',
  'pakka',
  'pakki',
  'pakki',
  'pakko',
  'paksu',
  'pakti',
  'palaa',
  'palho',
  'palje',
  'paljo',
  'palju',
  'palko',
  'palle',
  'palle',
  'palli',
  'palli',
  'pallo',
  'palmu',
  'paloa',
  'palsa',
  'palsa',
  'palte',
  'paluu',
  'palvi',
  'palvi',
  'pampa',
  'panda',
  'panna',
  'panna',
  'pannu',
  'panos',
  'panta',
  'pappa',
  'pappi',
  'paras',
  'parka',
  'parka',
  'parku',
  'parru',
  'parsa',
  'parsi',
  'parta',
  'party',
  'parvi',
  'parvi',
  'pasha',
  'pasma',
  'passi',
  'passi',
  'passi',
  'passi',
  'pasta',
  'patee',
  'patio',
  'patja',
  'patti',
  'patti',
  'pauhu',
  'pauke',
  'paula',
  'pauna',
  'peeaa',
  'peesi',
  'peffa',
  'pehko',
  'pehku',
  'pehmo',
  'pehva',
  'peili',
  'peite',
  'pekka',
  'pelko',
  'pelle',
  'pelti',
  'pelto',
  'peluu',
  'penne',
  'penni',
  'penny',
  'pensa',
  'pentu',
  'peoni',
  'peoni',
  'peppu',
  'perhe',
  'perho',
  'perin',
  'perin',
  'periä',
  'perna',
  'perso',
  'perua',
  'perus',
  'pervo',
  'pesin',
  'pesis',
  'pesiä',
  'peski',
  'pesti',
  'pesto',
  'pestä',
  'pesue',
  'pesye',
  'petos',
  'petsi',
  'pettu',
  'peura',
  'piano',
  'pidin',
  'pidot',
  'pieli',
  'piena',
  'pieni',
  'pieru',
  'pieti',
  'pietä',
  'pihiä',
  'pihka',
  'pihta',
  'pihvi',
  'piika',
  'piilo',
  'piilu',
  'piimä',
  'piina',
  'piiri',
  'piiru',
  'piiru',
  'piisi',
  'piisi',
  'pikaa',
  'pikee',
  'pikku',
  'pilke',
  'pilke',
  'pilli',
  'pilvi',
  'pimeä',
  'pimiö',
  'pinja',
  'pinko',
  'pinna',
  'pinne',
  'pinni',
  'pinta',
  'pioni',
  'pioni',
  'pirta',
  'pirtu',
  'pisiä',
  'piski',
  'piste',
  'pisto',
  'pitko',
  'pitkä',
  'pitsa',
  'pitsi',
  'pitää',
  'piuha',
  'pizza',
  'plari',
  'platy',
  'pläsi',
  'plörö',
  'pohja',
  'pohje',
  'poies',
  'poiju',
  'poika',
  'poimu',
  'pokka',
  'poksi',
  'polio',
  'polku',
  'polla',
  'polle',
  'polte',
  'polvi',
  'pommi',
  'pommi',
  'pomsi',
  'pondi',
  'ponsi',
  'pooli',
  'pooli',
  'poolo',
  'poppa',
  'porho',
  'porno',
  'poski',
  'possu',
  'posti',
  'posti',
  'posti',
  'potea',
  'potka',
  'potku',
  'potku',
  'potra',
  'potta',
  'potti',
  'potti',
  'pottu',
  'pottu',
  'pouta',
  'priki',
  'proto',
  'psori',
  'ptruu',
  'pudas',
  'puffi',
  'puhde',
  'puhki',
  'puhti',
  'puhua',
  'puhvi',
  'puida',
  'puija',
  'puite',
  'pujoa',
  'pujos',
  'pukea',
  'pukki',
  'pukki',
  'pulja',
  'pulju',
  'pulla',
  'pullo',
  'pulma',
  'pulmu',
  'pummi',
  'punka',
  'punoa',
  'punos',
  'punta',
  'puola',
  'puola',
  'puola',
  'puoli',
  'puomi',
  'puosu',
  'puoti',
  'puppu',
  'purje',
  'purjo',
  'purku',
  'purra',
  'purse',
  'pursi',
  'purso',
  'pursu',
  'pusia',
  'puska',
  'pusku',
  'pussi',
  'pusta',
  'putka',
  'putki',
  'putti',
  'putto',
  'puuha',
  'puuma',
  'puuro',
  'puute',
  'pygmi',
  'pykiä',
  'pylly',
  'pyree',
  'pyrky',
  'pyssy',
  'pysti',
  'pysty',
  'pystö',
  'pysyä',
  'pyton',
  'pytty',
  'pyyde',
  'pyyhe',
  'pyöry',
  'pyörä',
  'pyörö',
  'päivä',
  'päkiä',
  'pälvi',
  'pännä',
  'pässi',
  'päteä',
  'pätkä',
  'pätsi',
  'pääri',
  'pääsy',
  'pääte',
  'pääty',
  'pöhkö',
  'pöhnä',
  'pökkö',
  'pökät',
  'pölhö',
  'pöljä',
  'pölli',
  'pölly',
  'pöllö',
  'pörrö',
  'pötky',
  'pötkö',
  'pötsi',
  'pöytä',
  'quasi',
  'raaja',
  'raaka',
  'raaka',
  'raami',
  'raana',
  'raani',
  'raanu',
  'raape',
  'raasu',
  'raate',
  'raati',
  'raato',
  'rabbi',
  'radio',
  'radon',
  'radža',
  'rafla',
  'raguu',
  'rahje',
  'rahka',
  'rahna',
  'rahti',
  'rahtu',
  'raide',
  'railo',
  'raina',
  'raisu',
  'raita',
  'raita',
  'raita',
  'raito',
  'raivo',
  'rakas',
  'rakka',
  'rakki',
  'rakko',
  'raksa',
  'raksi',
  'ralli',
  'ralli',
  'ralli',
  'rambo',
  'rampa',
  'rangi',
  'ranka',
  'ranki',
  'ranko',
  'ranne',
  'ranta',
  'rantu',
  'rapea',
  'rappu',
  'rapse',
  'rapsi',
  'rapsu',
  'rasia',
  'rasko',
  'raspi',
  'rassi',
  'rassu',
  'rasta',
  'rasti',
  'rasva',
  'ratas',
  'ratki',
  'ratsu',
  'ratti',
  'ratti',
  'ratto',
  'rauha',
  'rauta',
  'rautu',
  'ravet',
  'ravit',
  'rehti',
  'reiki',
  'reikä',
  'reilu',
  'reima',
  'reisi',
  'reivi',
  'rekka',
  'rekka',
  'rekki',
  'reksi',
  'remmi',
  'renki',
  'rento',
  'repiä',
  'reppu',
  'repro',
  'reput',
  'respa',
  'ressu',
  'reteä',
  'retki',
  'retku',
  'reuma',
  'reuna',
  'revyy',
  'rieha',
  'riemu',
  'riena',
  'riepu',
  'riesa',
  'rievä',
  'rihla',
  'rihma',
  'riihi',
  'riimi',
  'riimu',
  'riimu',
  'riisi',
  'riisi',
  'riita',
  'riite',
  'riiuu',
  'rikas',
  'rikka',
  'rikki',
  'rikki',
  'rikko',
  'rikos',
  'riksa',
  'rikša',
  'riksi',
  'rimpi',
  'rimsu',
  'rinki',
  'rinne',
  'rinta',
  'ripeä',
  'rippi',
  'rippu',
  'ripsi',
  'ripsi',
  'ripsu',
  'riski',
  'riski',
  'risoa',
  'risti',
  'ritsa',
  'ritva',
  'riuku',
  'riuna',
  'rodeo',
  'rohjo',
  'rohmu',
  'rohto',
  'roihu',
  'roilo',
  'roima',
  'roina',
  'roisi',
  'rokka',
  'rokki',
  'rokko',
  'rombi',
  'rommi',
  'rommi',
  'rommi',
  'rompe',
  'rondi',
  'rondo',
  'rooli',
  'roosa',
  'ropse',
  'rosee',
  'roska',
  'rosti',
  'rosvo',
  'rotko',
  'rotsi',
  'rotta',
  'rouhe',
  'routa',
  'rouva',
  'rovio',
  'rugby',
  'ruhje',
  'rukka',
  'rukki',
  'rukki',
  'ruksi',
  'rulla',
  'rumba',
  'rumpu',
  'rundi',
  'runko',
  'ruode',
  'ruoho',
  'ruoja',
  'ruoka',
  'ruoko',
  'ruori',
  'ruoti',
  'ruoto',
  'ruotu',
  'rupia',
  'rupla',
  'ruska',
  'rusko',
  'rusto',
  'rutka',
  'rutto',
  'ruttu',
  'ruuhi',
  'ruuma',
  'ruuna',
  'ruusu',
  'ruuti',
  'ruutu',
  'ruuvi',
  'ryhmy',
  'ryhmä',
  'ryhti',
  'ryijy',
  'rykiä',
  'ryntö',
  'rypeä',
  'ryppy',
  'rypsi',
  'rypäs',
  'ryske',
  'ryssä',
  'rysty',
  'rytke',
  'rytky',
  'rytmi',
  'ryväs',
  'ryyni',
  'ryysy',
  'ryyti',
  'ryönä',
  'rähjä',
  'rähmä',
  'räike',
  'räkiä',
  'räkki',
  'rämeä',
  'ränni',
  'räntä',
  'räppi',
  'räpse',
  'rästi',
  'rätti',
  'rääsy',
  'räävi',
  'rölli',
  'römeä',
  'rönsy',
  'rösti',
  'rötös',
  'röyhy',
  'rööki',
  'rööri',
  'saada',
  'saaga',
  'saago',
  'šaahi',
  'saaja',
  'saali',
  'saame',
  'saari',
  'saate',
  'saati',
  'saavi',
  'sabra',
  'sadas',
  'sadin',
  'saeta',
  'safka',
  'sahra',
  'sahti',
  'sahuu',
  'saita',
  'sakea',
  'sakka',
  'sakki',
  'šakki',
  'sakko',
  'saksa',
  'salaa',
  'saldo',
  'salko',
  'salmi',
  'salon',
  'salpa',
  'salsa',
  'salva',
  'samba',
  'sambo',
  'samea',
  'sampi',
  'sampo',
  'samum',
  'sanka',
  'sanko',
  'sanoa',
  'santa',
  'sanue',
  'saota',
  'sappi',
  'sarja',
  'sarka',
  'sarka',
  'sarvi',
  'sataa',
  'satsi',
  'saudi',
  'sauhu',
  'sauma',
  'sauna',
  'sauro',
  'sauva',
  'saves',
  'scifi',
  'seili',
  'seimi',
  'seinä',
  'seipi',
  'seita',
  'seiti',
  'sekka',
  'sekki',
  'šekki',
  'seksi',
  'šelfi',
  'selin',
  'selja',
  'selko',
  'selkä',
  'selli',
  'sello',
  'sellu',
  'selus',
  'selvä',
  'selys',
  'seota',
  'seppo',
  'seppä',
  'serbi',
  'sermi',
  'serri',
  'servo',
  'setri',
  'setti',
  'seula',
  'seura',
  'seutu',
  'sidos',
  'sielu',
  'sieni',
  'siera',
  'sieto',
  'sietä',
  'sietä',
  'sievä',
  'sigma',
  'sihti',
  'sihti',
  'siika',
  'siili',
  'siili',
  'siilo',
  'siima',
  'siinä',
  'siipi',
  'siira',
  'siitä',
  'siitä',
  'siivo',
  'siivu',
  'sikeä',
  'sikhi',
  'sikiö',
  'sikli',
  'siksi',
  'silat',
  'sileä',
  'silko',
  'silla',
  'silli',
  'sillä',
  'silmu',
  'silmä',
  'silsa',
  'silta',
  'silti',
  'sinko',
  'sinne',
  'šinto',
  'sinut',
  'sioux',
  'sipsi',
  'sirri',
  'sisal',
  'sisar',
  'sisin',
  'sisko',
  'sissi',
  'sisus',
  'sitar',
  'siten',
  'sitko',
  'sitoa',
  'sitra',
  'sitsi',
  'siveä',
  'skeet',
  'skini',
  'skool',
  'slobo',
  'snadi',
  'snobi',
  'soeta',
  'softa',
  'sohia',
  'sohjo',
  'sohva',
  'soida',
  'soija',
  'soiro',
  'sokea',
  'sokka',
  'sokki',
  'šokki',
  'sokko',
  'solki',
  'solmu',
  'solua',
  'sompa',
  'sondi',
  'sonni',
  'sonta',
  'sooda',
  'soolo',
  'sooma',
  'sooni',
  'soopa',
  'soosi',
  'sopia',
  'soppa',
  'soppi',
  'sorea',
  'sorja',
  'sormi',
  'sorry',
  'sorsa',
  'sorto',
  'sorva',
  'sorvi',
  'sossu',
  'sotia',
  'sotka',
  'sotku',
  'soutu',
  'souvi',
  'spora',
  'spray',
  'sprii',
  'stadi',
  'stidi',
  'stout',
  'sueta',
  'suhde',
  'suhta',
  'sujua',
  'sujut',
  'sukia',
  'sukka',
  'suksi',
  'sulaa',
  'sulfa',
  'sulho',
  'sulje',
  'sulka',
  'sulku',
  'sumea',
  'summa',
  'sunna',
  'sunni',
  'suoda',
  'suoja',
  'suoja',
  'suoja',
  'suola',
  'suoli',
  'suomi',
  'suomu',
  'suoni',
  'suopa',
  'suora',
  'suova',
  'suppa',
  'suppo',
  'suppo',
  'surku',
  'surma',
  'surra',
  'surve',
  'sussu',
  'sutia',
  'sutki',
  'suttu',
  'suude',
  'suula',
  'suura',
  'suure',
  'suuri',
  'suute',
  'swing',
  'sydän',
  'sykli',
  'syksy',
  'sylki',
  'sylky',
  'syltä',
  'sylys',
  'synti',
  'synty',
  'syrjä',
  'sysiä',
  'syyhy',
  'syylä',
  'syyni',
  'syyte',
  'syödä',
  'syöjä',
  'syöpä',
  'sähke',
  'sähkö',
  'sähly',
  'säile',
  'säilä',
  'säilö',
  'säkki',
  'sälli',
  'sänki',
  'sänky',
  'säppi',
  'särki',
  'särky',
  'särmi',
  'särmä',
  'sätky',
  'sätkä',
  'sävel',
  'säyne',
  'sääde',
  'sääli',
  'sääri',
  'sääty',
  'säätö',
  'sössö',
  'taain',
  'taaja',
  'taala',
  'taara',
  'taata',
  'taata',
  'taeta',
  'tafti',
  'tahko',
  'tahma',
  'tahna',
  'tahra',
  'tahti',
  'tahto',
  'taide',
  'taiga',
  'taiji',
  'taika',
  'taimi',
  'taita',
  'taite',
  'taito',
  'taive',
  'taivo',
  'takaa',
  'takia',
  'takka',
  'takka',
  'takki',
  'takku',
  'takoa',
  'taksa',
  'taksi',
  'takuu',
  'talas',
  'talja',
  'talja',
  'talla',
  'talla',
  'talli',
  'talvi',
  'tamma',
  'tammi',
  'tammi',
  'tammi',
  'tango',
  'tanhu',
  'tanka',
  'tanko',
  'tappi',
  'tappo',
  'tapsi',
  'tarha',
  'tarke',
  'tarmo',
  'tarot',
  'tarra',
  'tarve',
  'tasan',
  'tasku',
  'tassi',
  'tassu',
  'tatar',
  'tatti',
  'tauko',
  'taula',
  'taulu',
  'tauti',
  'tavis',
  'teddy',
  'teddy',
  'teema',
  'teeri',
  'teesi',
  'tehdä',
  'teili',
  'teini',
  'tekno',
  'telje',
  'teljo',
  'telki',
  'teloa',
  'tempo',
  'tenho',
  'terho',
  'teriö',
  'termi',
  'terva',
  'terve',
  'teräs',
  'tesla',
  'tesma',
  'testi',
  'tetra',
  'tiede',
  'tiera',
  'tieto',
  'tietä',
  'tiheä',
  'tihku',
  'tiili',
  'tiima',
  'tiimi',
  'tiine',
  'tiinu',
  'tiira',
  'tikka',
  'tikki',
  'tikku',
  'tikli',
  'tilde',
  'tilhi',
  'tilke',
  'tilli',
  'tilsa',
  'tilus',
  'tinka',
  'tinki',
  'tippa',
  'tippi',
  'tirri',
  'tiski',
  'tisle',
  'tissi',
  'tiuha',
  'tiuku',
  'toeta',
  'toimi',
  'toive',
  'toivo',
  'tokka',
  'tokka',
  'tokko',
  'tollo',
  'tonne',
  'tonni',
  'tonus',
  'tooga',
  'toope',
  'toora',
  'toosa',
  'toppa',
  'toppi',
  'toppi',
  'torke',
  'torni',
  'torso',
  'torua',
  'torut',
  'torvi',
  'tosin',
  'tosio',
  'tossu',
  'totta',
  'touhu',
  'touko',
  'touvi',
  'trial',
  'tuhat',
  'tuhka',
  'tuhma',
  'tuhru',
  'tuhti',
  'tuhto',
  'tuija',
  'tuiju',
  'tuike',
  'tuiki',
  'tuima',
  'tukea',
  'tukka',
  'tukki',
  'tukko',
  'tukko',
  'tukku',
  'tukos',
  'tulla',
  'tulli',
  'tulos',
  'tulva',
  'tumma',
  'tunku',
  'tunne',
  'tunti',
  'tunto',
  'tuntu',
  'tuoda',
  'tuohi',
  'tuoja',
  'tuoli',
  'tuomi',
  'tuoni',
  'tuore',
  'tuote',
  'tupas',
  'tupee',
  'tupla',
  'tuppi',
  'tuppo',
  'tupsu',
  'turbo',
  'turha',
  'turku',
  'turma',
  'turpa',
  'turpa',
  'turri',
  'turso',
  'turta',
  'turva',
  'turve',
  'tuska',
  'tussi',
  'tussu',
  'tutia',
  'tutka',
  'tutor',
  'tutsi',
  'tutti',
  'tutti',
  'tuttu',
  'tutua',
  'tuuba',
  'tuubi',
  'tuuli',
  'tuuma',
  'tuuma',
  'tuura',
  'tuuri',
  'tweed',
  'twist',
  'tyhjä',
  'tyhjö',
  'tyhmä',
  'tykki',
  'tykki',
  'tykky',
  'tyköä',
  'tylli',
  'tylli',
  'tylsä',
  'tynkä',
  'typpi',
  'typäs',
  'tyriä',
  'tyrmä',
  'tyrni',
  'tytti',
  'tyttö',
  'tytär',
  'tyven',
  'tyyli',
  'tyyni',
  'tyyny',
  'tähde',
  'tähkä',
  'tähti',
  'tähän',
  'täkki',
  'täkki',
  'tälli',
  'tänne',
  'täplä',
  'tässä',
  'tästä',
  'täten',
  'täysi',
  'täyte',
  'töhkä',
  'tölli',
  'töniä',
  'törky',
  'törmä',
  'tötsä',
  'töyry',
  'uhata',
  'uhkea',
  'uhkua',
  'uhota',
  'uikku',
  'uinti',
  'uinua',
  'uisko',
  'uitto',
  'ujous',
  'ukuli',
  'ulina',
  'uljas',
  'ulkoa',
  'uloin',
  'uloke',
  'ulota',
  'ulvoa',
  'umbra',
  'ummet',
  'umpio',
  'unssi',
  'upeus',
  'upota',
  'upote',
  'upsis',
  'urhea',
  'urina',
  'usein',
  'useus',
  'uskoa',
  'utare',
  'utelu',
  'uudin',
  'uumen',
  'uupua',
  'uuras',
  'uurna',
  'uurre',
  'uurto',
  'uusia',
  'uutto',
  'uuttu',
  'vaade',
  'vaaka',
  'vaali',
  'vaara',
  'vaara',
  'vaari',
  'vaari',
  'vaasi',
  'vaata',
  'vaate',
  'vahti',
  'vahva',
  'vaihe',
  'vaimo',
  'vaino',
  'vainu',
  'vaisu',
  'vaiti',
  'vaiva',
  'vajaa',
  'vakaa',
  'vakio',
  'vakka',
  'vaksi',
  'valaa',
  'valas',
  'valhe',
  'valin',
  'valio',
  'valju',
  'valli',
  'valmu',
  'valos',
  'valta',
  'valua',
  'valve',
  'vamma',
  'vanha',
  'vanja',
  'vanki',
  'vanna',
  'vanne',
  'vanua',
  'vapaa',
  'vappu',
  'varas',
  'varho',
  'varis',
  'varjo',
  'varma',
  'varoa',
  'varpu',
  'varsa',
  'varsi',
  'varte',
  'varus',
  'varvi',
  'vasen',
  'vaski',
  'vasoa',
  'vasta',
  'vasta',
  'vaste',
  'vatja',
  'vatsa',
  'vatti',
  'watti',
  'vattu',
  'vaunu',
  'vauva',
  'weber',
  'vedin',
  'vedos',
  'vehje',
  'vehka',
  'vehnä',
  'veisu',
  'veivi',
  'vekki',
  'velho',
  'velka',
  'velli',
  'velmu',
  'venhe',
  'venho',
  'venyä',
  'venät',
  'veppi',
  'vepsä',
  'verbi',
  'veres',
  'verho',
  'verka',
  'verso',
  'verta',
  'verto',
  'veska',
  'veski',
  'vesoa',
  'vessa',
  'wessi',
  'vetää',
  'vichy',
  'video',
  'viedä',
  'viehe',
  'viejä',
  'vielä',
  'vieno',
  'vieri',
  'vihje',
  'vihko',
  'vihma',
  'vihma',
  'vihne',
  'vihta',
  'viila',
  'viili',
  'viilu',
  'viima',
  'viime',
  'viina',
  'viini',
  'viini',
  'viira',
  'viiri',
  'viiru',
  'viisi',
  'viisi',
  'viisu',
  'viita',
  'viite',
  'viiva',
  'viive',
  'viklo',
  'vilja',
  'vilke',
  'villa',
  'villa',
  'villi',
  'vimma',
  'vinha',
  'vinka',
  'vinka',
  'vinku',
  'viola',
  'vippa',
  'vippi',
  'vippi',
  'vippi',
  'vireä',
  'virhe',
  'viriö',
  'virka',
  'virke',
  'virna',
  'virne',
  'virpi',
  'virsi',
  'virsu',
  'virta',
  'virua',
  'virus',
  'visio',
  'viski',
  'vissi',
  'vissy',
  'visti',
  'visva',
  'vitja',
  'vitka',
  'vitoa',
  'vitsa',
  'vitsi',
  'viulu',
  'vodka',
  'vohla',
  'voida',
  'voide',
  'voima',
  'vokki',
  'vormu',
  'votka',
  'vouti',
  'vuode',
  'vuohi',
  'vuoka',
  'vuolu',
  'vuona',
  'vuono',
  'vuori',
  'vuori',
  'vuoro',
  'vuosi',
  'vuota',
  'vuoto',
  'vyöry',
  'vyöte',
  'vähin',
  'vähän',
  'väite',
  'väive',
  'väljä',
  'välke',
  'välys',
  'värve',
  'väsky',
  'väsyä',
  'vätys',
  'väylä',
  'väärä',
  'yhdes',
  'yhtiö',
  'yhtye',
  'yhtyä',
  'yhäti',
  'yksin',
  'yksiö',
  'yletä',
  'ylevä',
  'ylite',
  'ylpeä',
  'yltyä',
  'yltää',
  'ylväs',
  'ylävä',
  'yninä',
  'ynseä',
  'yrmeä',
  'yrtti',
  'yskiä',
  'yskös',
  'yöasu',
  'yökkö',
  'yöpuu',
  'yöpyä',
  'yötyö',
  'yötön',
  'yöuni',
  'zambo',
  'zloty',
  'zombi',
  'zoomi',
  'ähinä',
  'ähkiä',
  'ähkyä',
  'ähkää',
  'äiskä',
  'äityä',
  'äkeys',
  'äkkiä',
  'äkämä',
  'äkätä',
  'älinä',
  'älkää',
  'älytä',
  'ämyri',
  'änkkä',
  'äpäre',
  'äpärä',
  'äreys',
  'ärinä',
  'ärjyä',
  'ärtyä',
  'äsken',
  'äyräs',
  'ääliö',
  'äänes',
  'äänne',
  'ääntö',
  'öinen',
  'öisin',
  'ölinä',
  'örinä',
];
